import axios from "../api/axios";

const userUrl = "/user";
const userAvatarUrl = "/profile/avatar";
const userProfileUrl = "/profile";
const favoritesUrl = "/favorites";

export async function profileGet() {
  return await axios({
    method: "GET",
    url: userUrl,
    data: null,
  });
}

export async function avatarPost(formData) {
  return await axios({
    method: "POST",
    url: userAvatarUrl,
    data: formData,
    headers: { "content-type": "multipart/form-data" },
  });
}

export async function profilePost(formData) {
  return await axios({
    method: "POST",
    url: userProfileUrl,
    data: JSON.stringify(formData),
  });
}

export async function favoritesGet(paramsData) {
  return await axios({
    method: "GET",
    url: favoritesUrl,
    data: null,
    params: paramsData,
  });
}

export async function favoriteLike(formData) {
  return await axios({
    method: "POST",
    url: favoritesUrl,
    data: JSON.stringify(formData),
  });
}
