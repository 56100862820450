import CryptoJS from "crypto-js";

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_KEY
  ).toString();
};
export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_KEY
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    localStorage.clear();
    return window.location.replace('/login');
  }
};
