import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { BsReceipt } from "react-icons/bs";
import { BiMessageSquareDetail } from "react-icons/bi";
import { MdOutlineAccountCircle } from "react-icons/md";

export const FooterNavBar = () => {
  return (
    <>
      <nav className="fixed-bottom bg-body-tertiary py-2">
        <div className="d-flex flex-row align-items-center justify-content-evenly text-center">
          <Link to="/" className="text-decoration-none">
            <div>
              <AiFillHome className="fs-5" />
            </div>
            <small className="fs-20">Home</small>
          </Link>
          <Link to="/orders" className="text-decoration-none">
            <div>
              <BsReceipt className="fs-5" />
            </div>
            <small className="fs-20">Orders</small>
          </Link>
          <Link to="/" className="text-decoration-none">
            <div>
              <BiMessageSquareDetail className="fs-5" />
            </div>
            <small className="fs-20">Messages</small>
          </Link>
          <Link
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            className="text-decoration-none"
          >
            <div>
              <MdOutlineAccountCircle className="fs-5" />
            </div>
            <small className="fs-20">Account</small>
          </Link>
        </div>
      </nav>
    </>
  );
};
