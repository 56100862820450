import { createContext, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import toast from "react-hot-toast";
import { ToasterList } from "../data/ToasterList";
import { cartUpdate } from "../services/CartService";
import { useSearchParams } from "react-router-dom";

const notify_cart_add = () =>
  toast(ToasterList.cart_add.message, ToasterList.cart_add.options);
const notify_cart_remove = () =>
  toast(ToasterList.cart_remove.message, ToasterList.cart_remove.options);
const notify_cart_delete = () =>
  toast(ToasterList.cart_delete.message, ToasterList.cart_delete.options);

const CartContext = createContext({});

export const useShoppingCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useLocalStorage("shopping-cart", []);

  const myCartQuantity = cartItems.reduce(
    (quantity, item) =>
      item.quantity + quantity >= 100 ? "99+" : item.quantity + quantity,
    0
  );

  const incrementCartQuantity = (id) => {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id) == null) {
        cartUpdate({ item_id: id, cart_type: "add" });
        return [...currItems, { id, quantity: 1 }];
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            cartUpdate({ item_id: id, cart_type: "add" });
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      }
    });
    notify_cart_add();
  };
  const decrementCartQuantity = (id) => {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.id === id)?.quantity === 1) {
        cartUpdate({ item_id: id, cart_type: "delete" });
        return currItems.filter((item) => item.id !== id);
      } else {
        return currItems.map((item) => {
          if (item.id === id) {
            cartUpdate({ item_id: id, cart_type: "remove" });
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
    notify_cart_remove();
  };
  const removeFromCart = (id) => {
    setCartItems((currItems) => {
      cartUpdate({ item_id: id, cart_type: "delete" });
      return currItems.filter((item) => item.id !== id);
    });
    notify_cart_delete();
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const mySearch = (searchFor) => {
    Object.keys(searchFor).forEach((key) => {
      if (searchFor[key] && searchFor[key] != "") {
        searchParams.set(key, searchFor[key]);
      } else {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
    return searchParams;
  };

  return (
    <CartContext.Provider
      value={{
        incrementCartQuantity,
        decrementCartQuantity,
        removeFromCart,
        cartItems,
        myCartQuantity,
        setCartItems,
        mySearch,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
