import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LayoutMain from "../layouts/LayoutMain";
import { favoriteLike, favoritesGet } from "../services/AccountService";
import { FavoriteItem } from "../components/FavoriteItem";
import { useShoppingCart } from "../context/CartContext";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../data/HeaderTags";

export const Favorites = () => {
  const [myLikes, setMyLikes] = useState([]);
  const { mySearch } = useShoppingCart();

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    favoritesGet(query).then((response) => {
      let newLikes = response?.data;
      let likesArray =
        query.get("page") != 1
          ? [...(myLikes?.data ?? []), ...response?.data?.data]
          : [];
      if (likesArray != "") {
        newLikes.data = likesArray;
      }
      setMyLikes(newLikes);
    });
  }, [mySearch, setMyLikes]);

  const handelUnLike = (id) => {
    let newLike = [...myLikes?.data.filter((item) => item.id != id)];
    favoriteLike({ item_id: id, id: id, like_type: "unlike" });
    setMyLikes({ ...myLikes, data: newLike });
  };

  const handelPage = () => {
    const pageNo = query.get("page") ?? 1;
    const nextPage = Number(pageNo) + Number(1);
    mySearch({ page: nextPage });
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.favorites}</title>
      </Helmet>
      <LayoutMain>
        {myLikes?.data?.length > 0 ? (
          <>
            {myLikes.data.map((myLike, index) => (
              <div key={index}>
                <FavoriteItem myLike={myLike} onRemove={handelUnLike} />
              </div>
            ))}
            {myLikes?.current_page !== myLikes?.last_page ? (
              <div className="col-12 text-center mt-3">
                <button
                  className="btn btn-md btn-orange"
                  onClick={() => handelPage()}
                >
                  Load More
                </button>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <i className="bi bi-cart2 fs-1 fw-blod"></i>
              <div className="display-5">Your favorites is empty..</div>
              <div className="text-muted text-center mb-4">
                Start by adding favorite items and come back!
              </div>
              <Link
                to="/"
                className="text-decoration-none btn btn-md btn-orange"
              >
                <i className="bi bi-house-fill"></i> Home
              </Link>
            </div>
          </>
        )}
      </LayoutMain>
    </>
  );
};
