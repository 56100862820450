import { useContext } from "react";
import LayoutAuth from "../../layouts/LayoutAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { authLogin } from "../../services/AuthService";
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { BsFillKeyFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../../data/HeaderTags";
import { toast } from "react-hot-toast";
import { ToasterList } from "../../data/ToasterList";

const login_done = () =>
  toast(ToasterList.login_done.message, ToasterList.login_done.options);
const login_fail = () =>
  toast(ToasterList.login_fail.message, ToasterList.login_fail.options);

export const Login = () => {
  const { loginMe } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/";

  const onSubmit = async (data) => {
    authLogin({
      email: data.email,
      password: data.password,
    })
      .then((response) => {
        if (response?.status === 200) {
          loginMe(response?.data);
          login_done();
          reset({
            email: "",
            password: "",
          });
          navigate(redirectPath, { replace: true }); //REPLACE: TRUE - ARGUMENT IS FOR TO STOPE REDIRECTING TO LOGIN PAGE
        }
      })
      .catch((err) => {
        login_fail();
      });
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.login}</title>
      </Helmet>
      <LayoutAuth>
        <div className="container py-5 h-100 vh-100">
          <div className="card border-0 rounded-3 shadow-lg">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 w-50 d-none d-sm-none d-sm-block d-md-block d-xs-block">
                <div
                  className="rounded-start position-relative"
                  style={{
                    backgroundImage: `url('https://images.unsplash.com/photo-1421494756418-72f0f89c6bfd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80')`,
                    width: "100%",
                    height: "500px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
              <div className="flex-grow-1 text-center">
                <div className="row m-3">
                  <h2>
                    <Link className="navbar-brand" to="/">
                      <i className="bi bi-shop-window"></i>
                    </Link>
                  </h2>
                  <h4 className="fw-bold mb-2 text-capitalize">Log in</h4>
                  <p className="text-wrap mb-4">
                    Login with your email & password
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsFillEnvelopeAtFill />
                          </span>
                          <input
                            type="email"
                            {...register("email", {
                              required: "Email address is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Email address"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.email && <>{errors.email?.message}</>}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsFillKeyFill />
                          </span>
                          <input
                            type="password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            placeholder="Password"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.password && <>{errors.password?.message}</>}
                        </div>
                        <div className="text-end">
                          <Link to="/reset/password">Forgot password</Link>
                        </div>
                      </div>
                      <div className="d-grid gap-2 mb-4">
                        <button
                          className="btn btn-primary btn-sm text-uppercase"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                    <p className="mb-0">
                      Don't have an account?{" "}
                      <Link to="/register" className="fw-bold link">
                        Register
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutAuth>
    </>
  );
};
