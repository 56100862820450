import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "libphonenumber-js";
import { placeOrder } from "../services/OrderService";
import { useShoppingCart } from "../context/CartContext";
import toast from "react-hot-toast";
import { ToasterList } from "../data/ToasterList";

export const CartPlaceOrder = () => {
  const [orderType, setOrderType] = useState(1);
  const [payVia, setPayVia] = useState(1);
  const { setCartItems } = useShoppingCart();
  const order_place_success = () =>
    toast(ToasterList.order_place_success.message, ToasterList.order_place_success.options);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    placeOrder(data)
      .then((result) => {
        if (result?.status === 200) {
          setCartItems([]);
          order_place_success();
          navigate("/", { replace: true });
        }
      })
      .catch((e) => console.log(e?.response?.data?.message));
  };
  return (
    <div className="row g-3">
      <div>
        <div className="mb-2">Ordering type</div>
        <button
          type="button"
          onClick={() => setOrderType(1)}
          className={
            "btn btn-" + (orderType === 1 ? "" : "outline-") + "orange me-2"
          }
        >
          On Premise
        </button>
        <button
          type="button"
          onClick={() => setOrderType(2)}
          className={
            "btn btn-" + (orderType === 2 ? "" : "outline-") + "orange me-2"
          }
        >
          Takeaway
        </button>
      </div>
      <div>
        <div className="mb-2">Pay via</div>
        <button
          type="button"
          onClick={() => setPayVia(1)}
          className={
            "btn btn-" + (payVia === 1 ? "" : "outline-") + "orange me-2"
          }
        >
          Cash on hand / Card terminal
        </button>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("order_type")} value={orderType} />
        <input type="hidden" {...register("pay_via")} value={payVia} />
        <div className="row g-3">
          <div>
            <input
              {...register("details.name", {
                required: "Full name is required",
              })}
              className={`form-control rounded-end-2 ${
                errors.details?.name ? "is-invalid" : ""
              }`}
              placeholder="Full name"
            />
            <div className="form-text text-danger text-start">
              {errors.details?.name && <>{errors.details?.name?.message}</>}
            </div>
          </div>
          <div>
            {orderType === 1 ? (
              <>
                <input
                  type="number"
                  {...register("details.table_no", {
                    required: "Table number is required",
                    onBlur: (e) => {
                      clearErrors("details.phone");
                      reset({ details: { phone: "" } });
                    },
                  })}
                  className={`form-control rounded-end-2 ${
                    errors.details?.table_no ? "is-invalid" : ""
                  }`}
                  placeholder="Table number"
                />
                <div className="form-text text-danger text-start">
                  {errors.details?.table_no && (
                    <>{errors.details?.table_no?.message}</>
                  )}
                </div>
              </>
            ) : (
              <>
                <input
                  type="number"
                  {...register("details.phone", {
                    required: "Phone number is required",
                    onBlur: (e) => {
                      clearErrors("details.table_no");
                      reset({ details: { table_no: "" } });
                      clearErrors("details.phone");
                      if (isValidPhoneNumber(e.target.value, "MY") === false) {
                        setError("details.phone", {
                          message: e.target.value + " is not valid number!",
                        });
                      }
                    },
                  })}
                  className={`form-control rounded-end-2 ${
                    errors.details?.phone ? "is-invalid" : ""
                  }`}
                  placeholder="Phone number"
                />
                <div className="form-text text-danger text-start">
                  {errors.details?.phone && (
                    <>{errors.details?.phone?.message}</>
                  )}
                </div>
              </>
            )}
          </div>
          <div>
            <textarea
              {...register("details.message", {
                maxLength: {
                  value: 150,
                  message: "Message can have max length 150 characters!",
                },
              })}
              className={`form-control rounded-end-2 ${
                errors.details?.message ? "is-invalid" : ""
              }`}
              placeholder="Message"
            ></textarea>
            <div className="form-text text-danger text-start">
              {errors.details?.message && (
                <>{errors.details?.message?.message}</>
              )}
            </div>
          </div>
          <div className="d-grid">
            <button className="btn btn-orange border-0">Place order</button>
          </div>
        </div>
      </form>
    </div>
  );
};
