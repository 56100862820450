import { Link } from "react-router-dom";
import { LeftMenu } from "./LeftMenu";
import { useShoppingCart } from "../context/CartContext";
import { RiShoppingCartLine } from "react-icons/ri";
import { HiBuildingStorefront } from "react-icons/hi2";

export const HeaderNavBar = () => {
  const { myCartQuantity } = useShoppingCart();
  return (
    <>
      <nav className="navbar bg-body-tertiary fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <HiBuildingStorefront /> Shop
          </Link>
          <div className="position-relative me-2">
            <Link to="/cart">
              <RiShoppingCartLine className="fs-5" />
              {myCartQuantity > 0 ? (
                <>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-orange">
                    {myCartQuantity}
                  </span>
                </>
              ) : (
                ""
              )}
            </Link>
          </div>
          <LeftMenu />
        </div>
      </nav>
    </>
  );
};
