import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../hooks/DataCrypto";

const localToken = process.env.REACT_APP_LOCAL_TOKEN;

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkToken();
  }, [navigate, component]);

  const checkToken = useCallback(() => {
    try {
      const isAuth = decryptData(JSON.parse(localStorage.getItem(localToken)));
      if (!isAuth) {
        navigate("/login");
      } else if (isAuth?.verified_at == null) {
        navigate("/verify/code");
      }
      setStatus(true);
      return;
    } catch (error) {
      navigate("/login");
    }
  });

  return status ? (
    <React.Fragment>{component}</React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default AuthGuard;
