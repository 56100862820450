import { useState, useEffect, useCallback } from "react";
import LayoutMain from "../layouts/LayoutMain";
import { useParams } from "react-router-dom";
import { MdAddCircle } from "react-icons/md";
import { MdRateReview } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { getItems } from "../services/ItemService";
import { favoriteLike } from "../services/AccountService";
import { Helmet } from "react-helmet";

export const Item = () => {
  const [loading, setLoading] = useState(true);
  const itemId = useParams()?.id;
  const [itemData, setItemData] = useState([]);
  const [favoriteIcon, setFavoriteIcon] = useState(false);

  const getItemsById = useCallback(async () => {
    getItems({ item_id: itemId }).then((result) => {
      setItemData(result?.data?.data);
      setFavoriteIcon(result?.data?.data[0]?.favorite === 1 ? true : false);
      setLoading(false);
    });
  });

  useEffect(() => {
    getItemsById();
  }, []);

  const handelFavorite = () => {
    const like_type = favoriteIcon === true ? "unlike" : "like";
    setFavoriteIcon(favoriteIcon === true ? false : true);
    favoriteLike({ item_id: itemData[0]?.id, like_type });
  };

  const [rating, setRating] = useState(0);
  const starRating = (num) => (
    <AiOutlineStar
      className="me-1 text-orange fs-4"
      onMouseEnter={() => HandelRating(num)}
    />
  );
  const starRatingHOver = (num) => (
    <AiFillStar
      className="me-1 text-orange fs-4"
      onMouseEnter={() => HandelRating(num)}
    />
  );

  const HandelRating = (ratingNumber) => {
    setRating(ratingNumber);
  };

  return (
    <>
      <LayoutMain>
        {loading === true ? (
          <>
            <div className="card shadow-sm border mb-2 placeholder-glow">
              <div className="img-background-card-200 placeholder w-auto"></div>
              <div className="card-body pb-0">
                <span className="placeholder col-7"></span>
              </div>
              <div className="card-body d-flex flex-row align-items-center justify-content-between py-2">
                <span className="placeholder col-8"></span>
                <button className="btn btn-orange disabled placeholder col-3"></button>
              </div>
              <div className="card-body text-justify pt-0">
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-7"></span>
              </div>
            </div>
          </>
        ) : (
          <>
            {itemData?.length > 0 ? (
              <>
                <Helmet>
                  <title>{itemData[0]?.name}</title>
                </Helmet>
                <div className="row">
                  <div className="col-12 col-xl-6 col-lg-5 col-md-8 mx-auto">
                    <div className="card shadow-sm border mb-2">
                      <div
                        className="img-background rounded-top position-relative"
                        style={{
                          backgroundImage: `url(${itemData[0]?.item_img_url})`,
                        }}
                      >
                        <div
                          className="position-absolute bottom-0 end-0 mb-3 me-4"
                          onClick={handelFavorite}
                        >
                          <h1>
                            {favoriteIcon === true ? (
                              <AiFillHeart className="text-danger" />
                            ) : (
                              <AiOutlineHeart className="text-danger" />
                            )}
                          </h1>
                        </div>
                      </div>
                      <div className="card-body pb-0">
                        <span className="fs-120 fw-bold d-inline-block mw-100 text-truncate">
                          {itemData[0]?.name}
                        </span>
                        <div className="small">
                          {itemData[0]?.item_menu_name}
                        </div>
                      </div>
                      <div className="card-body d-flex flex-row align-items-center justify-content-between py-2">
                        <span className="fs-5 fw-500">
                          <small>RM</small>{" "}
                          <strong>{(itemData[0]?.price).toFixed(2)}</strong>
                        </span>
                        <button className="btn btn-sm btn-orange border-0">
                          <MdAddCircle className="fs-3" /> Add to cart
                        </button>
                      </div>
                      <div className="card-body py-3 text-justify">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: itemData[0]?.desc,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="card shadow-sm border mb-2">
                      <div className="card-body py-3 text-justify border-bottom">
                        <div className="display-6 card-title border-bottom">
                          Reviews
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-start mb-1">
                          {rating >= 1 ? starRatingHOver(1) : starRating(1)}
                          {rating >= 2 ? starRatingHOver(2) : starRating(2)}
                          {rating >= 3 ? starRatingHOver(3) : starRating(3)}
                          {rating >= 4 ? starRatingHOver(4) : starRating(4)}
                          {rating >= 5 ? starRatingHOver(5) : starRating(5)}
                        </div>
                        <textarea className="form-control mb-2"></textarea>
                        <div className="d-grid gap-2 justify-content-end">
                          <button className="btn btn-sm btn-orange">
                            <MdRateReview /> Submit
                          </button>
                        </div>
                      </div>
                      <div className="card-body py-2 text-justify border-bottom">
                        <small className="me-auto">
                          User Name
                          <br />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <br />
                          <small>2022-12-31 11:30 PM</small>
                        </small>
                        <div>Test message!</div>
                      </div>
                      <div className="card-body py-2 text-justify border-bottom">
                        <small className="me-auto">
                          User Name
                          <br />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiOutlineStar className="me-1 text-orange" />
                          <br />
                          <small>2022-12-31 11:30 PM</small>
                        </small>
                        <div>Test message!</div>
                      </div>
                      <div className="card-body py-2 text-justify border-bottom">
                        <small className="me-auto">
                          User Name
                          <br />
                          <AiFillStar className="me-1 text-orange" />
                          <AiFillStar className="me-1 text-orange" />
                          <AiOutlineStar className="me-1 text-orange" />
                          <AiOutlineStar className="me-1 text-orange" />
                          <AiOutlineStar className="me-1 text-orange" />
                          <br />
                          <small>2022-12-31 11:30 PM</small>
                        </small>
                        <div>Test message!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>No data!</h1>
            )}
          </>
        )}
      </LayoutMain>
    </>
  );
};
