import { Link } from "react-router-dom";
import { BsPlusCircleFill } from "react-icons/bs";

export const FavoriteItem = ({ myLike, onRemove }) => {
  return (
    <div className="card border-0 rounded-3">
      <div className="d-flex p-2 border-bottom align-items-center">
        <div className="pe-2 flex-shrink-0">
          <Link to={"/item/" + myLike?.item_like?.item_id}>
            <div
              className="img-background-card-80 rounded position-relative"
              style={{
                backgroundImage: `url(${myLike?.item_like?.item_img_url})`,
              }}
            ></div>
          </Link>
        </div>
        <div className="flex-grow-1 text-truncate">
          {myLike?.item_like?.name}
          <div className="fw-500">
            <div>
              <small className="fw-semibold">
                <span className="cart-fs-small">RM </span>
                {(myLike?.item_like?.price).toFixed(2)}
              </small>
            </div>
            <div>
              <div className="d-flex flex-row">
                <div>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary"
                    onClick={() => onRemove(myLike?.id)}
                  >
                    Unlike
                  </button>
                </div>
                <div className="ms-auto">
                  {myLike?.item_like?.is_live === 1 ? (
                    <BsPlusCircleFill className="text-orange fs-4 curser-pointer" />
                  ) : (
                    <Link
                      to={"/item/" + myLike?.item_like?.item_id}
                      className="btn btn-sm btn-outline-success curser-pointer text-decoration-none"
                    >
                      Live View
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
