import React, { useRef, useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export const CropImageModal = ({ src, cropDataImg }) => {
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const CropThisDemo = useRef(null);
  useEffect(() => {
    CropThisDemo.current.cropper.options.minContainerHeight = 500;
    CropThisDemo.current.cropper.options.minContainerWidth = 500;
    cropData !== "#" && cropDataImg(cropData);
  }, [CropThisDemo, cropData, cropDataImg]);

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <>
      <div className="col-12">
        <div
          className="modal fade"
          id="modalDemo"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
                  Crop
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <Cropper
                  style={{ height: "500px", width: "500px" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  src={src}
                  center={true}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                  ref={CropThisDemo}
                />
              </div>
              <div className="modal-footer">
                {src && (
                  <>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={getCropData}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Save
                    </button>
                  </>
                )}
                <button
                  className="btn btn-sm btn-light"
                  data-bs-target="#exampleModalToggle"
                  data-bs-toggle="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
