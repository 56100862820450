const title = process.env.REACT_APP_TITLE + " :: ";

export const HeaderTags = {
  title: {
    login: `${title} Login Page.`,
    register: `${title} Sign Up Page.`,
    verifyCode: `${title} Sign Up Page.`,
    resetPwd: `${title} Forgot Password Page.`,
    home: `${title} Home Page.`,
    cart: `${title} Cart Page.`,
    orders: `${title} Orders Page.`,
    profile: `${title} Profile Page.`,
    favorites: `${title} Favorites Page.`,
  },
  meta: {
    description: "Welcome to Self Order System App",
  },
};
