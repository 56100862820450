import { useState } from "react";
import LayoutAuth from "../../layouts/LayoutAuth";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../../data/HeaderTags";
import { useForm } from "react-hook-form";
import { BsFillEnvelopeAtFill, BsShopWindow } from "react-icons/bs";
import { MdOutlinePassword, MdVerifiedUser } from "react-icons/md";
import { forgotPassword } from "../../services/AuthService";
import { toast } from "react-hot-toast";
import { ToasterList } from "../../data/ToasterList";

const forgot_pwd_done = (errMsg) =>
  toast(
    errMsg ? errMsg : ToasterList.forgot_pwd_done.message,
    ToasterList.forgot_pwd_done.options
  );
const forgot_pwd_fail = () =>
  toast(
    ToasterList.forgot_pwd_fail.message,
    ToasterList.forgot_pwd_fail.options
  );

export const ResetPassword = () => {
  const [codeFlag, setCodeFlag] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    data.email_code = codeFlag === true ? false : true;
    if (data.email) {
      setCodeFlag(true);
    }
    forgotPassword({
      email_code: data.email_code,
      email: data.email,
      password: data.password,
      code: data.code,
    })
      .then((response) => {
        if (response?.status === 200) {
          forgot_pwd_done(response?.data?.message);
          console.log()
          if (codeFlag === false) {
            reset({
              email_code: false,
              password: "",
              code: "",
            });
          }
          if(data.email_code === false){
            navigate(`/login`, { replace: true }); //REPLACE: TRUE - ARGUMENT IS FOR TO STOPE REDIRECTING TO LOGIN PAGE
          }
        }
      })
      .catch((e) => forgot_pwd_fail());
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.resetPwd}</title>
      </Helmet>
      <LayoutAuth>
        <div className="container py-5 h-100 vh-100">
          <div className="card border-0 rounded-3 shadow-lg">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 w-50 d-none d-sm-none d-sm-block d-md-block d-xs-block">
                <div
                  className="rounded-start position-relative"
                  style={{
                    backgroundImage: `url('https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=775&q=80')`,
                    width: "100%",
                    height: "500px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
              <div className="flex-grow-1 text-center">
                <div className="row m-3">
                  <h2>
                    <Link className="navbar-brand" to="/">
                      <BsShopWindow />
                    </Link>
                  </h2>
                  <h4 className="fw-bold mb-4 text-capitalize">
                    Reset Password
                  </h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsFillEnvelopeAtFill />
                          </span>
                          <input
                            type="email"
                            readOnly={codeFlag}
                            {...register("email", {
                              required: "Email address is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Email address"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.email && <>{errors.email?.message}</>}
                        </div>
                      </div>
                      {codeFlag === true ? (
                        <>
                          <div className="col-12">
                            <div className="input-group">
                              <span className="input-group-text">
                                <MdVerifiedUser />
                              </span>
                              <input
                                type="number"
                                {...register("code", {
                                  required: "Verification code is required",
                                  maxLength: 6,
                                })}
                                className={`form-control rounded-end-2 ${
                                  errors.code ? "is-invalid" : ""
                                }`}
                                placeholder="Verification code"
                              />
                            </div>
                            <div className="text-danger text-start">
                              {errors.code && <>{errors.code?.message}</>}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="input-group">
                              <span className="input-group-text">
                                <MdOutlinePassword />
                              </span>
                              <input
                                type="password"
                                {...register("password", {
                                  required: "Password is required",
                                  minLength: 6,
                                })}
                                className={`form-control rounded-end-2 ${
                                  errors.password ? "is-invalid" : ""
                                }`}
                                placeholder="Password"
                              />
                            </div>
                            <div className="text-danger text-start">
                              {errors.password && (
                                <>{errors.password?.message}</>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="d-grid gap-2">
                        <button
                          className="btn btn-primary btn-sm mb-4 text-uppercase"
                          type="submit"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                    <p className="mb-0">
                      Don't have an account?{" "}
                      <Link to="/register" className="fw-bold link">
                        Register
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutAuth>
    </>
  );
};
