import axios from "../api/axios";

// const endPointUrl = process.env.REACT_APP_API_BASE_URL + "/api";
const itemsUrl = "/items";
const ddListUrl = "/dd_list";

export async function getDDList(userData) {
  return await axios({
    method: "post",
    url: ddListUrl,
    data: JSON.stringify(userData),
  });
}

export async function getItems(paramsData) {
  return await axios({
    method: "get",
    url: itemsUrl,
    data: null,
    params: paramsData
  });
  // return await axios.get(itemsUrl, JSON.stringify(userData));
}
