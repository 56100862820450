import axios from "axios";
import { decryptData } from "../hooks/DataCrypto";

let objHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: null,
};

let AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/api",
  headers: objHeader,
});

AxiosInstance.interceptors.request.use(function (config) {
  const tokenName = process.env.REACT_APP_LOCAL_TOKEN;
  const tokenLocal = JSON.parse(localStorage.getItem(tokenName));
  if (tokenLocal != null) {
    const authToken = decryptData(tokenLocal);
    config.headers["Authorization"] = `Bearer ${authToken.token}`;
  }
  return config;
});

export default AxiosInstance;
