import React, { useState, useEffect, useCallback } from "react";
import LayoutMain from "../layouts/LayoutMain";
import { CartItems as CartItemsCoponent } from "../components/CartItems";
import { Link } from "react-router-dom";
import { useShoppingCart } from "../context/CartContext";
import { getMyCart } from "../services/CartService";
import { CartPlaceOrder } from "../components/CartPlaceOrder";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../data/HeaderTags";

export const Cart = () => {
  const [loading, setLoading] = useState(true);
  const { cartItems, setCartItems } = useShoppingCart();
  const [myCartItems, setMyCartItems] = useState([]);

  const resetAsyncForm = useCallback(async () => {
    const result = await getMyCart();
    setMyCartItems(result?.data);
    const localCart = result?.data.map((item) => {
      return {
        id: item.item_id,
        quantity: item.quantity,
      };
    });
    setCartItems(localCart);
    setLoading(false);
  }, [setMyCartItems]);

  useEffect(() => {
    resetAsyncForm();
  }, [resetAsyncForm]);

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.cart}</title>
      </Helmet>
      <LayoutMain>
        {loading === true ? (
          <>
            <div className="card border-0 rounded-3" aria-hidden="true">
              <div className="d-flex p-2 border-bottom align-items-center placeholder-glow">
                <div className="pe-2 flex-shrink-0">
                  <div className="img-background-card-80 rounded position-relative placeholder bg-secondary"></div>
                </div>
                <div className="flex-grow-1 text-truncate">
                  <h5 className="card-title mb-0">
                    <span className="placeholder col-12"></span>
                  </h5>
                  <div className="fw-500">
                    <div className="d-flex flex-row">
                      <div>
                        <small className="fw-semibold">
                          <span className="placeholder col-12"></span>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <span className="placeholder col-12"></span>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {cartItems.length > 0 ? (
              <>
                {cartItems.map((item) => (
                  <CartItemsCoponent
                    key={item.id}
                    item={item}
                    dbCart={myCartItems}
                  />
                ))}
                <div className="cart-divider my-3"></div>
                <div className="card py-3 px-2 border-1">
                  <div className="d-flex flex-row align-items-center justify-content-start fw-bold fs-5">
                    <div>Total</div>
                    <div className="ms-auto text-info-emphasis">
                      <span className="cart-fs-small">RM </span>
                      {cartItems
                        .reduce((total, cartItem) => {
                          const itemCost = myCartItems.find(
                            (i) => i?.item_obj?.item_id === cartItem.id
                          );
                          return (
                            total +
                            (itemCost?.item_obj?.price ?? 0) * cartItem.quantity
                          );
                        }, 0)
                        .toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="card pt-3 px-2 border-0">
                  <div className="d-flex flex-column align-items-start justify-content-start">
                    <CartPlaceOrder />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                  <i className="bi bi-cart2 fs-1 fw-blod"></i>
                  <div className="display-5">Your cart is empty..</div>
                  <div className="text-muted text-center mb-4">
                    Start by adding some items to your cart and come back!
                  </div>
                  <Link
                    to="/"
                    className="text-decoration-none btn btn-md btn-orange"
                  >
                    <i className="bi bi-house-fill"></i> Home
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      </LayoutMain>
    </>
  );
};
