import axios from "../api/axios";

const cartsUrl = "/cart";

export async function getMyCart() {
  return await axios({
    method: "GET",
    url: cartsUrl,
    data: null,
  });
}

export async function cartUpdate(bodyData) {
  return await axios({
    method: "POST",
    url: cartsUrl,
    data: JSON.stringify(bodyData),
    params: null,
  });
}
