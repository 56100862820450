import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css/bundle";
import { Link, useLocation } from "react-router-dom";
import { getDDList } from "../services/ItemService";

export const SlideSwiperBar = () => {
  const [itemsMenu, setItemsMenu] = useState([]);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    getDDList({ row_id: 58 })
      .then((result) => {
        setItemsMenu(result?.data);
      })
      .catch((e) => console.log("Error in fetching!"));
  }, []);
  const classAddAll = !query.get("menu") ? "orange border-0" : "outline-orange";

  return (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={10}
      modules={[FreeMode]}
      freeMode={true}
      watchSlidesProgress={true}
    >
      <SwiperSlide>
        <Link
          to="/"
          className={"btn btn-" + classAddAll + " rounded-5 me-3 px-4"}
        >
          All
        </Link>
        <>
          {Object.keys(itemsMenu).map((index) => {
            const classAdd =
              query.get("menu") === index
                ? "orange border-0"
                : "outline-orange";
            return (
              <Link
                key={index}
                to={"?menu=" + index}
                className={"btn btn-" + classAdd + " rounded-5 me-3 px-4"}
              >
                {itemsMenu[index]}
              </Link>
            );
          })}
        </>
      </SwiperSlide>
    </Swiper>
  );
};
