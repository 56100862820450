import { BsPlusCircleFill } from "react-icons/bs";
import { BsFillDashCircleFill } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiLogIn } from "react-icons/bi";
import { BiErrorCircle } from "react-icons/bi";
import { BiUser } from "react-icons/bi";
import { BiUserX } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";

const commonOptions = {
  duration: 1000,
  position: "top-center",
  className: "",
  icon: "",
};

export const ToasterList = {
  login_done: {
    message: "Login successfull!",
    options: {
      ...commonOptions,
      icon: <BiLogIn className="text-success" />,
    },
  },
  login_fail: {
    message: "Login failed!",
    options: {
      ...commonOptions,
      icon: <BiErrorCircle className="text-danger" />,
    },
  },
  reg_done: {
    message: "Sign up successfull!",
    options: {
      ...commonOptions,
      icon: <BiUser className="text-success" />,
    },
  },
  reg_fail: {
    message: "Sign up failed!",
    options: {
      ...commonOptions,
      icon: <BiUserX className="text-danger" />,
    },
  },
  forgot_pwd_done: {
    message: "Password updated successfull!",
    options: {
      ...commonOptions,
      icon: <AiFillCheckCircle className="text-success" />,
    },
  },
  forgot_pwd_fail: {
    message: "Password update failed!",
    options: {
      ...commonOptions,
      icon: <BsFillDashCircleFill className="text-danger" />,
    },
  },
  vCode_done: {
    message: "Verification code successfull!",
    options: {
      ...commonOptions,
      icon: <AiFillCheckCircle className="text-success" />,
    },
  },
  vCode_fail: {
    message: "Verification code not matching!",
    options: {
      ...commonOptions,
      icon: <AiFillCloseCircle className="text-danger" />,
    },
  },
  cart_add: {
    message: "Added!",
    options: {
      ...commonOptions,
      icon: <BsPlusCircleFill className="text-success" />,
    },
  },
  cart_remove: {
    message: "Removed!",
    options: {
      ...commonOptions,
      icon: <BsFillDashCircleFill className="text-danger" />,
    },
  },
  cart_delete: {
    message: "Deleted!",
    options: {
      ...commonOptions,
      icon: <BsFillTrashFill className="text-danger" />,
    },
  },
  order_place_success: {
    message: "Order Successfully placed!",
    options: {
      ...commonOptions,
      icon: <AiFillCheckCircle className="text-success" />,
    },
  },
};
