import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const localToken = process.env.REACT_APP_LOCAL_TOKEN;

const UnAuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkToken();
  }, [navigate, component]);

  const checkToken = () => {
    try {
      const isAuth = localStorage.getItem(localToken);
      if (!isAuth) {
        localStorage.removeItem(localToken);
      } else {
        navigate(`/`);
      }
      setStatus(true);
    } catch (error) {
      navigate(`/`);
    }
  };

  return status ? (
    <React.Fragment>{component}</React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default UnAuthGuard;
