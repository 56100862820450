import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { BsShieldCheck } from "react-icons/bs";
import { BiPowerOff } from "react-icons/bi";
import { AuthContext } from "../context/AuthContext";
import { logoutMe } from "../services/AuthService";
import { AiOutlineHeart } from "react-icons/ai";

export const LeftMenu = () => {
  const { logout } = useContext(AuthContext);
  const [user, setUser] = useState();

  const navigate = useNavigate();

  const handelClick = () => {
    logoutMe().then((response) => {
      if (response?.status === 200) {
        logout();
        navigate("/login");
      }
    });
  };
  useEffect(() => {
    const bsInfo = process.env.REACT_APP_LOCAL_BASIC_INFO;
    setUser(JSON.parse(localStorage.getItem(bsInfo)));
  }, []);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
            <i className="bi bi-list"></i> Menu
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body pt-0">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item">
              <div className="d-flex flex-column align-items-start justify-content-start">
                <div className="fs-1 bi bi-person-circle"></div>
                <strong className="fs-4">
                  <img
                    src={user?.avatar_path}
                    className="avatar-40"
                    alt={user?.name}
                  />{" "}
                  {user?.name}
                </strong>
              </div>
              <hr />
            </li>
            <li
              className="nav-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <Link className="nav-link" aria-current="page" to="/profile">
                <BiUserCircle /> Profile
              </Link>
            </li>
            <li
              className="nav-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <Link className="nav-link" aria-current="page" to="/favorites">
                <AiOutlineHeart /> Favorites
              </Link>
            </li>
            <li
              className="nav-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <Link className="nav-link" aria-current="page" to="#">
                <BsShieldCheck /> Term & Conditions / privacy
              </Link>
              <hr />
            </li>
            <li
              className="nav-item"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <Link
                className="nav-link"
                aria-current="page"
                to="#"
                onClick={handelClick}
              >
                <BiPowerOff /> Log out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
