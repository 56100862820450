import axios from "../api/axios";

// const endPointUrl = process.env.REACT_APP_API_BASE_URL + "/api";
const authLocalRegister = "/auth/register";
const authLocalLogin = "/auth/login";
const authLocalLogout = "/auth/logout";
const AuthVerifyCode = "/auth/verify_code";
const AuthForgotPassword = "/auth/forgot-password";

export async function authRegister(userData) {
  return await axios.post(authLocalRegister, JSON.stringify(userData));
}

export async function authLogin(userData) {
  return await axios.post(authLocalLogin, JSON.stringify(userData));
}

export async function verifyCode(code) {
  return await axios.post(AuthVerifyCode, JSON.stringify({ code }));
}

export async function forgotPassword(objCode) {
  return await axios.post(AuthForgotPassword, JSON.stringify(objCode));
}

export async function logoutMe() {
  return await axios({
    method: "POST",
    url: authLocalLogout,
    data: null,
  });
}
