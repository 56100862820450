import { useShoppingCart } from "../context/CartContext";
import { BsFillTrashFill } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";
import { BsFillDashCircleFill } from "react-icons/bs";

export const CartItems = ({ item, dbCart }) => {
  const { incrementCartQuantity, decrementCartQuantity, removeFromCart } =
    useShoppingCart();
  const dbItem = dbCart.find((i) => i?.item_obj?.item_id === item.id);
  if (dbItem == null) return null;

  return (
    <div className="card border-0 rounded-3">
      <div className="d-flex p-2 border-bottom align-items-center">
        <div className="pe-2 flex-shrink-0">
          <div
            className="img-background-card-80 rounded position-relative"
            style={{
              backgroundImage: `url(${dbItem?.item_obj?.item_img_url})`,
            }}
          ></div>
        </div>
        <div className="flex-grow-1 text-truncate">
          {dbItem?.item_obj?.name}
          <div className="fw-500">
            <div className="d-flex flex-row">
              <div>
                <small className="fw-semibold">
                  <span className="cart-fs-small">RM </span>
                  {(dbItem?.item_obj?.price).toFixed(2)}{" "}
                  <span className="text-muted cart-fs-small">
                    {" "}
                    x {item.quantity >= 1 && item.quantity}
                  </span>
                </small>
              </div>
              <div className="ms-auto">
                <BsFillTrashFill
                  className="text-danger curser-pointer"
                  onClick={() => removeFromCart(dbItem?.item_obj?.item_id)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <BsPlusCircleFill
              className="text-orange fs-4 curser-pointer"
              onClick={() => incrementCartQuantity(dbItem?.item_obj?.item_id)}
            />
            <div className="align-baseline text-center px-1 cart-count-value">
              {item.quantity >= 1 && item.quantity}
            </div>
            <BsFillDashCircleFill
              className="text-orange fs-4 curser-pointer"
              onClick={() => decrementCartQuantity(dbItem?.item_obj?.item_id)}
            />
            <div className="ms-auto text-info-emphasis align-self-end">
              <small>
                <span className="cart-fs-small">RM </span>
                {(dbItem?.item_obj?.price * item.quantity).toFixed(2)}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
