import { React, createContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { encryptData } from "../hooks/DataCrypto";

const localToken = process.env.REACT_APP_LOCAL_TOKEN;
const basicInfo = process.env.REACT_APP_LOCAL_BASIC_INFO;

export const AuthContext = createContext(null);

const AuthContextProvider = (props) => {
  const [token, setToken] = useLocalStorage(localToken, null);
  const [accBasicInfo, setAccBasicInfo] = useLocalStorage(basicInfo, null);

  const loginMe = async (userData) => {
    const tokenObj = {
      token: userData?.token,
      verified_at: userData?.verified_at,
    };
    setToken(encryptData(tokenObj));
    setAccBasicInfo({
      name: userData?.message?.name,
      avatar_path: userData?.message?.avatar_path,
      em: encryptData(userData?.message?.email),
    });

    // const enData = encryptData({ access_token: "hello world!" });
    // const deData = decryptData(enData);
  };

  const logout = () => {
    setToken(null);
    setAccBasicInfo(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ token, accBasicInfo, loginMe, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

// export const useAuth = () => {
//     return useContext(AuthProvider);
// }
