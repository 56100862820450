import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LayoutMain from "../layouts/LayoutMain";
import { BsCircleFill } from "react-icons/bs";
import { myOrders } from "../services/OrderService";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../data/HeaderTags";

const order_status_color = {
  1: "primary",
  2: "warning",
  3: "success",
  4: "danger",
};

const order_status = {
  1: "New",
  2: "In-Progress",
  3: "Completed",
  4: "Cancelled",
};

export const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    myOrders().then((response) => {
      setOrders(response?.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.orders}</title>
      </Helmet>
      <LayoutMain>
        {orders?.data?.length > 0 ? (
          <>
            {orders.data.map((order, index) => (
              <div className="card border-0 rounded-0 mb-3" key={index}>
                <div className="d-flex flex-row align-items-center justify-content-between p-2 border-bottom">
                  <div className="fw-bold">
                    <span className="text-info">#{order.order_id}</span>
                  </div>
                  <div className="text-end text-capitalize small">
                    <div
                      className={`text-${
                        order_status_color[order?.order_status]
                      } small`}
                    >
                      <BsCircleFill /> {order_status[order.order_status]}
                    </div>
                    <div>
                      <span className="small">{order.order_at}</span>
                    </div>
                  </div>
                </div>
                {order?.order_items.length > 0 ? (
                  <>
                    {order.order_items.map((orderItem, keyIndex) => (
                      <div
                        className="d-flex p-2 border-bottom align-items-center"
                        key={keyIndex}
                      >
                        <div className="pe-2 flex-shrink-0">
                          <div
                            className="img-background-card-60 rounded position-relative"
                            style={{
                              backgroundImage: `url(${orderItem?.item_obj?.item_img_url})`,
                            }}
                          ></div>
                        </div>
                        <div className="flex-grow-1 text-truncate">
                          {orderItem?.item_obj?.name}
                          <div className="d-flex flex-row">
                            <div>
                              <small className="fw-semibold">
                                <span className="cart-fs-small">RM </span>
                                {(orderItem?.item_obj?.price).toFixed(2)}
                              </small>
                            </div>
                            <div className="ms-auto">
                              <span className="text-muted cart-fs-small">
                                {orderItem?.quantity}x
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="d-flex p-2 border-bottom align-items-center">
                      <div className="pe-2 flex-shrink-0">
                        <div>Total</div>
                      </div>
                      <div className="flex-grow-1 text-truncate">
                        <div className="d-flex flex-row">
                          <div className="ms-auto">
                            <span className="cart-fs-small">
                              <small className="fw-semibold">
                                <span className="cart-fs-small">RM </span>
                                {order.order_items
                                  .reduce((total, orderItem) => {
                                    return (
                                      total +
                                      (orderItem?.item_obj?.price ?? 0) *
                                        orderItem.quantity
                                    );
                                  }, 0)
                                  .toFixed(2)}
                              </small>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <i className="bi bi-cart2 fs-1 fw-blod"></i>
              <div className="display-5">Your orders is empty..</div>
              <div className="text-muted text-center mb-4">
                Start by placing order and come back!
              </div>
              <Link
                to="/"
                className="text-decoration-none btn btn-md btn-orange"
              >
                <i className="bi bi-house-fill"></i> Home
              </Link>
            </div>
          </>
        )}
      </LayoutMain>
    </>
  );
};
