import LayoutAuth from "../../layouts/LayoutAuth";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authRegister } from "../../services/AuthService";
import { BsPersonFill } from "react-icons/bs";
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { BsFillKeyFill } from "react-icons/bs";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../../data/HeaderTags";
import { toast } from "react-hot-toast";
import { ToasterList } from "../../data/ToasterList";

const reg_done = () =>
  toast(ToasterList.reg_done.message, ToasterList.reg_done.options);
const reg_fail = () =>
  toast(ToasterList.reg_fail.message, ToasterList.reg_fail.options);

export const Register = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const response = await authRegister({
        name: data.full_name,
        email: data.email,
        password: data.password,
      });
      if (response?.status === 200) {
        reg_done();
        reset({
          full_name: "",
          email: "",
          password: "",
        });
      }
    } catch (err) {
      reg_fail();
    }
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.register}</title>
      </Helmet>
      <LayoutAuth>
        <div className="container py-5 h-100 vh-100">
          <div className="card border-0 rounded-3 shadow-lg">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 w-50 d-none d-sm-none d-sm-block d-md-block d-xs-block">
                <div
                  className="rounded-start position-relative"
                  style={{
                    backgroundImage: `url('https://images.unsplash.com/photo-1512149177596-f817c7ef5d4c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80')`,
                    width: "100%",
                    height: "500px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
              <div className="flex-grow-1 text-center">
                <div className="row m-3">
                  <h2>
                    <Link className="navbar-brand" to="/">
                      <i className="bi bi-shop-window"></i>
                    </Link>
                  </h2>
                  <h4 className="fw-bold mb-2 text-capitalize">Register</h4>
                  <p className="text-wrap mb-4">
                    By signing up, you agree to ourterms & policy
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsPersonFill />
                          </span>
                          <input
                            {...register("full_name", {
                              required: "Full name is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.full_name ? "is-invalid" : ""
                            }`}
                            placeholder="Full name"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.full_name && <>{errors.full_name?.message}</>}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsFillEnvelopeAtFill />
                          </span>
                          <input
                            type="email"
                            {...register("email", {
                              required: "Email address is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Email address"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.email && <>{errors.email?.message}</>}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <BsFillKeyFill />
                          </span>
                          <input
                            type="password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            placeholder="Password"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.password && <>{errors.password?.message}</>}
                        </div>
                      </div>
                      <div className="d-grid gap-2 mb-4">
                        <button
                          className="btn btn-primary btn-sm text-uppercase"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                    <p className="mb-0">
                      Already have an account?{" "}
                      <Link to="/login" className="fw-bold link">
                        Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutAuth>
    </>
  );
};
