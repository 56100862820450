import axios from "../api/axios";

const placeOrderUrl = "/order/place_order";
const ordersUrl = "/order";

export async function placeOrder(bodyData) {
  return await axios({
    method: "POST",
    url: placeOrderUrl,
    data: JSON.stringify(bodyData),
    params: null,
  });
}

export async function myOrders(bodyData) {
  return await axios({
    method: "GET",
    url: ordersUrl,
    data: JSON.stringify(bodyData),
    params: null,
  });
}
