import React from "react";
import { HeaderNavBar } from "./HeaderNavBar";
import { FooterNavBar } from "./FooterNavBar";

const LayoutMain = (props) => (
  <>
      <HeaderNavBar />
      <div className="container my-5 py-4">{props.children}</div>
      <FooterNavBar />
  </>
);

export default LayoutMain;
