import { Routes, Route } from "react-router-dom";
import AuthContextProvider from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
import { Toaster } from "react-hot-toast";
import AuthGuard from "./guards/AuthGuard";
import UnAuthGuard from "./guards/UnAuthGuard";
import { Login } from "./pages/auth/Login";
import { Register } from "./pages/auth/Register";
import { ResetPassword } from "./pages/auth/ResetPassword";
import { Home } from "./pages/Home";
import { Item } from "./pages/Item";
import { Cart } from "./pages/Cart";
import { Profile } from "./pages/Profile";
import { Orders } from "./pages/Orders";
import { Favorites } from "./pages/Favorites";
import { VerifyCode } from "./pages/auth/VerifyCode";

function App() {
  return (
    <>
      <AuthContextProvider>
        <CartProvider>
          <Routes>
            <Route
              path="/login"
              element={<UnAuthGuard component={<Login />} />}
            />
            <Route
              path="/register"
              element={<UnAuthGuard component={<Register />} />}
            />
            <Route
              path="/verify/code"
              element={<AuthGuard component={<VerifyCode />} />}
            />
            <Route
              path="/reset/password"
              element={<UnAuthGuard component={<ResetPassword />} />}
            />
            <Route path="/" element={<AuthGuard component={<Home />} />} />
            <Route
              path="/item/:id"
              element={<AuthGuard component={<Item />} />}
            />
            <Route path="/cart" element={<AuthGuard component={<Cart />} />} />
            <Route
              path="/orders"
              element={<AuthGuard component={<Orders />} />}
            />
            <Route
              path="/profile"
              element={<AuthGuard component={<Profile />} />}
            />
            <Route
              path="/favorites"
              element={<AuthGuard component={<Favorites />} />}
            />
          </Routes>
          <Toaster />
        </CartProvider>
      </AuthContextProvider>
    </>
  );
}

export default App;
