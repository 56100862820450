import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LayoutMain from "../layouts/LayoutMain";
import { BiSearch } from "react-icons/bi";
import { BsGrid } from "react-icons/bs";
import { MdAddCircle } from "react-icons/md";
import { SlideSwiperBar } from "../components/SlideSwiperBar";
import { getItems } from "../services/ItemService";
import { useShoppingCart } from "../context/CartContext";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../data/HeaderTags";

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const [q, setQ] = useState("");
  const { incrementCartQuantity, mySearch } = useShoppingCart();

  const [items, setItems] = useState([]);

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    getItems(query).then((result) => {
      let newItems = result?.data;
      let itemArray =
        query.get("page") != 1
          ? [...(items?.data ?? []), ...result?.data?.data]
          : [];
      if (itemArray != "") {
        newItems.data = itemArray;
      }
      setItems(newItems);
      setLoading(false);
    });
  }, [mySearch]);

  const handelSearch = () => {
    mySearch({ search: q, page: 1 });
  };

  const handelPage = () => {
    const pageNo = query.get("page") ?? 1;
    const nextPage = Number(pageNo) + Number(1);
    mySearch({ page: nextPage });
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.home}</title>
      </Helmet>
      <LayoutMain>
        <>
          <div className="input-group mb-4">
            <input
              type="search"
              name="search"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              className="form-control"
              placeholder="Search"
            />
            <button
              className="btn btn-outline-orange"
              type="button"
              id="button-addon2"
              onClick={handelSearch}
            >
              <BiSearch />
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <SlideSwiperBar />
            </div>
            <div className="ps-2">
              <BsGrid className="fs-2" />
            </div>
          </div>
          {loading === true ? (
            <>
              <div className="row row-cols-2 row-cols-lg-6 g-2">
                <div className="col placeholder-glow">
                  <div className="card shadow-sm border">
                    <div className="img-background-card-100 placeholder bg-secondary w-auto"></div>
                    <div className="card-body pb-0 text-body-emphasis">
                      <span className="text-truncate">
                        <span className="placeholder col-12"></span>
                      </span>
                    </div>
                    <div className="card-body d-flex flex-row align-items-center justify-content-between pt-1">
                      <span className="placeholder col-12"></span>
                    </div>
                  </div>
                </div>
                <div className="col placeholder-glow">
                  <div className="card shadow-sm border">
                    <div className="img-background-card-100 placeholder bg-secondary w-auto"></div>
                    <div className="card-body pb-0 text-body-emphasis">
                      <span className="text-truncate">
                        <span className="placeholder col-12"></span>
                      </span>
                    </div>
                    <div className="card-body d-flex flex-row align-items-center justify-content-between pt-1">
                      <span className="placeholder col-12"></span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {items?.data?.length > 0 ? (
                <>
                  <div className="row row-cols-2 row-cols-lg-6 g-2">
                    {items.data.map((item, index) => (
                      <div className="col" key={index}>
                        <div className="card shadow-sm border">
                          <Link
                            to={"/item/" + item.item_id}
                            className="text-decoration-none link-bg-dark"
                          >
                            <div className="ratio ratio-4x3">
                              <img
                                src={item.item_img_url}
                                alt=""
                                className="card-img-top"
                              />
                            </div>
                            <div className="card-body pb-0 text-body-emphasis">
                              <span className="d-inline-block mw-100 text-truncate">
                                {item.name}
                              </span>
                            </div>
                          </Link>
                          <div className="card-body d-flex flex-row align-items-center justify-content-between pt-1">
                            <strong className="text-orange">
                              <sup className="cart-fs-small">RM </sup>
                              {item.price.toFixed(2)}
                            </strong>
                            <MdAddCircle
                              className="text-orange fs-4 pointer hvr-grow curser-pointer"
                              onClick={() =>
                                incrementCartQuantity(item?.item_id)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {items?.current_page !== items?.last_page ? (
                    <div className="col-12 text-center mt-3">
                      <button
                        className="btn btn-md btn-orange"
                        onClick={handelPage}
                      >
                        Load More
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <div className="row m-5">
                    <div className="col-12 text-center">
                      <h3>No Item avalable!</h3>
                      <Link to="/" className="btn btn-lg btn-orange">
                        Home
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      </LayoutMain>
    </>
  );
};
