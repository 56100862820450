import React, { useState, useEffect, useCallback } from "react";
import LayoutMain from "../layouts/LayoutMain";
import { MdSave } from "react-icons/md";
import { CropImageModal } from "../components/CropImageModal";
import { Modal } from "bootstrap";
import {
  profileGet,
  avatarPost,
  profilePost,
} from "../services/AccountService";
import { useForm } from "react-hook-form";
import { BsPersonFill } from "react-icons/bs";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Helmet } from "react-helmet";
import { HeaderTags } from "../data/HeaderTags";

const basicInfo = process.env.REACT_APP_LOCAL_BASIC_INFO;

export const Profile = () => {
  const [myProfile, setMyProfile] = useLocalStorage(basicInfo, null);

  const [message, setMessage] = useState({ message: null, color: "success" });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const resetAsyncForm = useCallback(async () => {
    const result = await profileGet();
    setMyProfile({
      name: result?.data?.name,
      avatar_path: result?.data?.avatar_path,
    });
    setValue("full_name", result?.data.name);
  }, [setValue]);

  useEffect(() => {
    resetAsyncForm();
  }, [resetAsyncForm]);

  const onSubmit = async (data) => {
    console.log(data);
    profilePost({ name: data.full_name }).then((response) => {
      setMessage({ message: "Full name updated.", color: "success" });
      if (response?.status === 200) {
        setMyProfile({
          name: response?.data?.name,
          avatar_path: response?.data?.avatar_path,
        });
      }
    });
  };

  const [file, setFile] = useState();
  const [croppedModalImg, setCroppedModalImg] = useState("");
  const handleChange = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    const showModal = new Modal("#modalDemo");
    showModal.show();
    e.preventDefault();
  };

  const handelAvatar = () => {
    fetch(croppedModalImg)
      .then((data) => data.blob())
      .then((blob) => {
        const formData = new FormData();
        const fileName = new File([blob], "avatar.png");
        formData.append("avatar_file", fileName, fileName.name);
        avatarPost(formData).then((response) => {
          setMessage({ message: "Profile picture updated.", color: "success" });
          if (response?.status === 200) {
            setMyProfile({
              name: response?.data?.name,
              avatar_path: response?.data?.avatar_path,
            });
          }
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.profile}</title>
      </Helmet>
      <LayoutMain>
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-5 col-md-8 mx-auto">
            <div className="card w-auto shadow border">
              <div className="card-body">
                <div className="row">
                  <div className="d-flex flex-column align-items-center justify-content-center col-12 position-relative">
                    <div className={`text-${message?.color} mb-4`}>
                      {message?.message}
                    </div>
                    <label htmlFor="upload_file" className="curser-pointer">
                      <div
                        className="card rounded-circle"
                        style={{
                          width: "150px",
                          height: "150px",
                          backgroundImage: `url(${
                            !croppedModalImg
                              ? myProfile?.avatar_path
                              : croppedModalImg
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <input
                          type="file"
                          className="d-none"
                          id="upload_file"
                          onChange={handleChange}
                        />
                      </div>
                    </label>
                    <MdSave
                      className="fs-2 p-1 bg-orange text-white curser-pointer rounded-circle position-absolute bottom-0 start-50 profile-save-icon-transform"
                      onClick={handelAvatar}
                    />
                  </div>
                  <CropImageModal src={file} cropDataImg={setCroppedModalImg} />
                  <div className="col-12">
                    <div className="text-center fw-bold fs-4">
                      {myProfile?.email}
                    </div>
                    <hr />

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-12">
                          <div className="input-group">
                            <span className="input-group-text">
                              <BsPersonFill />
                            </span>
                            <input
                              {...register("full_name", {
                                required: "Full name is required",
                              })}
                              className={`form-control rounded-end-2 ${
                                errors.full_name ? "is-invalid" : ""
                              }`}
                              placeholder="Full name"
                            />
                          </div>
                          <div className="text-danger text-start">
                            {errors.full_name && (
                              <>{errors.full_name?.message}</>
                            )}
                          </div>
                        </div>
                        <div className="d-grid gap-2 mb-4">
                          <button
                            className="btn btn-orange btn-sm text-uppercase border-0"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMain>
    </>
  );
};
