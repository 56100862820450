export const MaskEmail = (email) => {
  let split = email.split("@");
  return (
    split[0].substr(0, 4) +
    new Array(split[0].length - 4).fill("*").join("") +
    "@" +
    split[1].substr(0, 4) +
    new Array(split[1].length - 4).fill("*").join("")
  );
};
