import { Helmet } from "react-helmet";
import { HeaderTags } from "../../data/HeaderTags";
import LayoutAuth from "../../layouts/LayoutAuth";
import { Link, useNavigate } from "react-router-dom";
import { MdVerifiedUser } from "react-icons/md";
import { AiFillShop } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { decryptData } from "../../hooks/DataCrypto";
import { logoutMe, verifyCode } from "../../services/AuthService";
import { ToasterList } from "../../data/ToasterList";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState, useEffect } from "react";
import { MaskEmail } from "../../hooks/MaskEmail";

const localToken = process.env.REACT_APP_LOCAL_TOKEN;
const basicInfo = process.env.REACT_APP_LOCAL_BASIC_INFO;

const vCode_done = () =>
  toast(ToasterList.vCode_done.message, ToasterList.vCode_done.options);
const vCode_fail = () =>
  toast(ToasterList.vCode_fail.message, ToasterList.vCode_fail.options);
const login_fail = () =>
  toast(ToasterList.login_fail.message, ToasterList.login_fail.options);

export const VerifyCode = () => {
  const { loginMe } = useContext(AuthContext);
  const accountBasic = JSON.parse(localStorage.getItem(basicInfo));
  const eMailId = decryptData(accountBasic?.em);

  const [countDown, setCountDown] = useState(60);

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  useEffect(() => {
    countDown > 0 && setTimeout(() => setCountDown(countDown - 1), 1000);
  }, [countDown]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const handelVerify = (data) => {
    verifyCode(data.verify_code)
      .then((response) => {
        if (response?.status === 200) {
          loginMe(response?.data);
          vCode_done();
          reset({
            verify_code: "",
          });
          navigate("/", { replace: true });
        } else {
          vCode_fail();
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          login_fail();
          localStorage.removeItem(localToken);
          navigate("/login", { replace: true });
        }
      });
  };

  const { logout } = useContext(AuthContext);

  const handelClick = () => {
    logoutMe().then((response) => {
      if (response?.status === 200) {
        logout();
        navigate("/login");
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{HeaderTags.title.verifyCode}</title>
      </Helmet>
      <LayoutAuth>
        <div className="container py-5 h-100 vh-100">
          <div className="card border-0 rounded-3 shadow-lg">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 w-50 d-none d-sm-none d-sm-block d-md-block d-xs-block">
                <div
                  className="rounded-start position-relative"
                  style={{
                    backgroundImage: `url('https://images.unsplash.com/photo-1421494756418-72f0f89c6bfd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80')`,
                    width: "100%",
                    height: "500px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
              </div>
              <div className="flex-grow-1 text-center">
                <div className="row m-3">
                  <h1>
                    <Link className="navbar-brand" to="/">
                      <AiFillShop />
                    </Link>
                  </h1>
                  <h4 className="fw-bold mb-2 text-capitalize">
                    Verify your email address
                  </h4>
                  <p className="text-wrap mb-4">
                    An Authentecation code has been sent to
                    <br />
                    <b>{MaskEmail(eMailId)}</b>
                    <br />
                    Enter the code below to confirm your email address.
                  </p>
                  <form onSubmit={handleSubmit(handelVerify)}>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="input-group">
                          <span className="input-group-text">
                            <MdVerifiedUser />
                          </span>
                          <input
                            type="text"
                            {...register("verify_code", {
                              required: "Verification code is required",
                            })}
                            className={`form-control rounded-end-2 ${
                              errors.verify_code ? "is-invalid" : ""
                            }`}
                            placeholder="Verification code"
                          />
                        </div>
                        <div className="text-danger text-start">
                          {errors.verify_code && (
                            <>{errors.verify_code?.message}</>
                          )}
                        </div>
                      </div>
                      <div className="d-grid gap-2 mb-4">
                        <button
                          className="btn btn-primary btn-sm text-uppercase"
                          type="submit"
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </form>
                  <div>
                    <p className="mb-0">
                      {countDown === 0 ? (
                        <Link to="/register" className="fw-bold link">
                          Resend code
                        </Link>
                      ) : (
                        <>
                          Resend Code in{" "}
                          <span>{zeroPad(countDown, 2)} Sec.</span>
                        </>
                      )}
                    </p>
                    <p>
                      <button
                        type="submit"
                        className="btn btn-danger btn-sm text-uppercase mt-4"
                        onClick={handelClick}
                      >
                        Back
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutAuth>
    </>
  );
};
